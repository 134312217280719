.container {
    width: 100%;
    justify-content: center;
    margin-top: 150px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.title {
    color: var(--Primary-1-normal, #2e496f);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.button {
    width: 168px;
    height: 48px;
    padding: 10px 28px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--Primary-1-normal, #2e496f);
    color: var(--2, #fff);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
