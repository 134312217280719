.modal :global(.ant-modal-content) {
	padding: 40px 56px;
}

.modal :global(.ant-modal-close) {
	width: 32px;
	height: 32px;
	color: #000000;
	background-color: #F6F8FD;
}

.root {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	gap: 16px;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttons {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
	padding-block: 16px;
}

.button {
	background-color: transparent;
	border: none;
	transition: all 0.2s;
	padding: 8px 16px;
	border-radius: 6px;
}

.button:not(:disabled):hover {
	cursor: pointer;
}

.button:not(:disabled):hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.button:not(:disabled):active {
	background-color: rgba(0, 0, 0, 0.08);
}

.button:disabled {
	color: gray;
}

.card {
	display: flex;
	flex-direction: column;
	background-color: #e9eef7;
	border: 1px solid #f0f0f0;
	padding: 20px;
	border-radius: 8px;
	gap: 8px;
}
.cardTitle {
	margin: 0;
	position: "relative";
	color: #2e496f;
}
.cardTitle :last-child {
	display: inline-block;
	color: #327fef;
	font-size: 14px;
	transform: translateY(-3px);
	margin-left: 2px;
}
.cardBody {
	flex-grow: 1;
	background-color: #ffffff;
	border-radius: 4px;
}
.cardBody::-webkit-scrollbar {
	width: 6px;
}
.cardBody::-webkit-scrollbar-thumb {
	background-color: #e6edf6;
	border: 1px solid #8eafdb;
	border-radius: 4px;
}

.item {
	padding: 8px 12px;
	border-bottom: 1px solid #e9eef7;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	user-select: none;
	font-size: 13px;
}

.item > :first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	height: 18px;
	border-radius: 2px;
	background-color: #ffffff;
	color: #ffffff;
	font-size: 12px;
	border: 1px solid #989aa5;
}
.item:hover > :first-child {
	border-color: #1677ff;
}

.itemAdded {
	background-color: rgb(221, 255, 221);
}
.itemRemoved {
	background-color: rgb(255, 238, 234);
}

.itemSelected > :first-child {
	background-color: #1677ff;
	border-color: #1677ff;
}
.drawer :global(.ant-drawer-inline) {
	height: calc(100vh - 105px) !important;
	overflow: hidden;
}
.drawer :global(.ant-drawer) {
	height: calc(100vh - 105px) !important;
	overflow: hidden;
}

.button{
    background-color: #76CEC9;
    max-width: 150px;
    width: 100%;
    height: 36px;
    border: none;
    color: white;
    text-align: center;
    padding: 0;
    border-radius: 2px;
    cursor: pointer;
    gap: 15px;
	font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
}

.buttonUser{
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.buttonClose{
	max-width: 150px;
    width: 100%;
    height: 36px;
    border: none;
    text-align: center;
    padding: 0;
    border-radius: 2px;
	font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
	background: #F6F8FD;
}
